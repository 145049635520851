<template>
    <list-condition-template ref="table" :rows="rows" :total="total"
     @page-info-change="handlePageInfoChange" :defaultSort="{prop:'updateTime',order:'descending'}"
      v-model:listQuery="listQuery" :index-column="true">
      <template #condition>
        <el-form-item>
          <div class="demo-date-picker">
            <div class="block">
              <span class="demonstration">筛选日期：</span>
              <el-date-picker
                clearable
                value-format="YYYY-MM-DD"
                v-model="valueDate"
                type="daterange"
                range-separator="至"
                start-placeholder="开始时间"
                end-placeholder="结束时间">
              </el-date-picker>
            </div>
          </div>
        </el-form-item>
        <el-form-item>
          <el-input @keyup.enter="handleFilter" style="width: 250px;" placeholder="物料代码/型号/制造商/订单批次  查询" v-model="listQuery.condition.keyword" clearable></el-input>
        </el-form-item>
        <el-form-item>
          <el-button class="filter-item" style="margin-left: 10px;" type="primary" v-waves icon="el-icon-search" @click="handleFilter">搜索</el-button>
        </el-form-item>
      </template>
    <template #columns >

        <el-table-column label="订单批次" width="110px" align="center">
          <template  #default="scope">
            <span>{{scope.row.order?scope.row.order.orderBatch:''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="物料代码" width="110px" align="center">
          <template  #default="scope">
            <span>{{scope.row.model?scope.row.model.materialCode:''}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="labelApply.creatorName" label="申请人" width="100" align="center"/>
        <el-table-column label="型号" min-width="100px" align="center">
          <template  #default="scope">
            <span>{{scope.row.model?scope.row.model.name:''}}</span>
          </template>
        </el-table-column>
                <el-table-column label="制造商" property="manufacturer.name" min-width="200px" align="center">
          <template #default="scope">
            <span>{{scope.row.manufacturer?scope.row.manufacturer.name:''}}</span>
          </template>
        </el-table-column>
        <el-table-column width="60px" label="制造商代码" align="center">
          <template  #default="scope">
            <span>{{scope.row.manufacturer?scope.row.manufacturer.code:''}}</span>
          </template>
        </el-table-column>
        <el-table-column width="80px" label="防伪码标签（套）" property="labelCount" align="center">
          <template #default="scope">
            <span>{{scope.row.labelCount}}</span>
          </template>
        </el-table-column>
        <el-table-column label="申请类型" width="100" align="center">
          <template #default="scope">
            {{scope.row.applyType&&scope.row.applyType.type}}
          </template>
        </el-table-column>
        <el-table-column label="支付类型" width="100" align="center">
          <template #default="scope">
            {{scope.row.payType&&scope.row.payType.type}}
          </template>
        </el-table-column>
        <el-table-column width="80" label="表单拆分" align="center">
          <template #default="scope">
            <el-tag type="success">{{scope.row.split}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="140px" label="生成时间" property="createTime" align="center" sortable="label">
          <template  #default="scope">
            <span>{{scope.row.createTime}}</span>
          </template>
        </el-table-column>
        <el-table-column width="200px" label="起始标签" align="center">
          <template  #default="scope">
            <span>{{scope.row.startLabel}}</span>
          </template>
        </el-table-column>
        <el-table-column width="200px" label="结束标签" align="center" >
          <template  #default="scope">
            <span>{{scope.row.endLabel}}</span>
          </template>
        </el-table-column>


        <el-table-column width="50px" label="单位" align="center">
          <template  #default="scope">
            <span>{{scope.row.model?scope.row.model.unit:''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="类型"  width="80px" align="center">
          <template  #default="scope">
            <el-tag :type="showFilter(scope.row)">{{scope.row.model.type && scope.row.model.type.title || ''}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column width="50px" label="联数" align="center" >
          <template  #default="scope">
            <span>{{scope.row.model?scope.row.model.page:''}}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="150px" class-name="fixed-width">
          <template  #default="scope">
            <el-button size="mini" type="primary" @click="handleShow(scope.row)" v-waves>查看</el-button>
          </template>
        </el-table-column>
    </template>
  </list-condition-template>
</template>

<script>
import waves from '@/directive/waves' // 水波纹指令
import listConditionTemplate from '@/components/templates/listConditionTemplate'
import labelListApi from '@/apis/label/list'
export default {
  directives: {
    waves
  },
  components: {
    listConditionTemplate
  },
  data () {
    return {
      valueDate: undefined,
      rows: null,
      total: 0,
      listQuery: {
        condition:{
          keyword: null,
          createStartTime: undefined,
          createEndTime: undefined
        },
        pageInfo:{
          pageIndex: 1,
          pageSize: 20,
          orders: []
        }
      }
    }
  },
  filters: {
  },
  methods: {
    showFilter (row) {
      return row.model.type && row.model.type.value === 'HOME_APPLIANCES' ? 'success' : 'warning'
    },
    handleFilter () {
      if(this.valueDate){
        this.listQuery.condition.createStartTime = this.valueDate[0] + " 00:00:00"
        this.listQuery.condition.createEndTime = this.valueDate[1] + " 23:59:59"
      }else{
        this.listQuery.condition.createStartTime = undefined
        this.listQuery.condition.createEndTime = undefined
      }
      this.getList()
    },
    handlePageInfoChange () {
      this.getList()
    },
    getList () {
      labelListApi
        .getPaged(this.listQuery)
        .then(response => {
          this.rows = response.data.content
          this.total = response.data.totalElements
        })
    },
    handleShow(row){
      this.$router.pushTo(this.$router.routesConfig.labelDetails, row.id)
    }
  }
}
</script>
